import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';


export interface Poll { 
  name: string; 
  question: string; 
  answer1: string; 
  answer2: string; 
  answer3: string; 
}

@Component({
  selector: 'app-answer-poll',
  templateUrl: './answer-poll.component.html',
  styleUrls: ['./answer-poll.component.css']
})
export class AnswerPollComponent implements OnInit {

  ngOnInit() {
  }
  private itemDoc: AngularFirestoreDocument<Poll>;
  item: Observable<Poll> ;
  afs: AngularFirestore;
  constructor(afs: AngularFirestore) {
    this.afs=afs;
    this.itemDoc=afs.doc<Poll>('polls/test');
    this.item=this.itemDoc.valueChanges();
  }

  doAnswer(number) {
    this.afs.collection('polls/test/answers').add({ answer: number});
  }
}
