import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-view-poll',
  templateUrl: './view-poll.component.html',
  styleUrls: ['./view-poll.component.css']
})
export class ViewPollComponent implements OnInit {

 

  ngOnInit() {
  }
  answers: Observable<any[]>;
  constructor(afs: AngularFirestore) {
    this.answers=afs.collection('polls/test/answers').valueChanges();
  }


}
