import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatButtonModule, MatCheckboxModule,MatInputModule} from '@angular/material';
import {MatListModule} from '@angular/material/list';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ListPollsComponent } from './list-polls/list-polls.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ReactiveFormsModule } from '@angular/forms';


//import {FirebaseUIModule} from 'firebaseui-angular';
//import * as firebase from 'firebase/app';
//import * as firebaseui from 'firebaseui';
// currently there is a bug while building the app with --prod
// - https://github.com/RaphaelJenni/FirebaseUI-Angular/issues/76
// the plugin exposes the two libraries as well. You can use those:
import {FirebaseUIModule, firebase, firebaseui} from 'firebaseui-angular';
import { EditPollComponent } from './edit-poll/edit-poll.component';
import { AnswerPollComponent } from './answer-poll/answer-poll.component';
import { ViewPollComponent } from './view-poll/view-poll.component';
import { FireFormDirective } from './fire-form.directive';

const appRoutes: Routes = [
  { path: 'listpolls', component: ListPollsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'editpoll', component: EditPollComponent },
  { path: 'viewpoll', component: ViewPollComponent },
  { path: 'answerpoll', component: AnswerPollComponent },
  { path: '',   redirectTo: '/listpolls', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'redirect',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      scopes: [
        'public_profile',
        'email',
        'user_likes',
        'user_friends'
      ],
      customParameters: {
        'auth_type': 'reauthenticate'
      },
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
    },
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    },
    firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  tosUrl: '<your-tos-link>',
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM
};

@NgModule({
  declarations: [
    AppComponent,
    ListPollsComponent,
    LoginComponent,
    PageNotFoundComponent,
    EditPollComponent,
    AnswerPollComponent,
    ViewPollComponent,
    FireFormDirective
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase, 'firepoll'),
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    MatButtonModule,MatInputModule,
    MatCheckboxModule,MatListModule,MatFormFieldModule,
    ReactiveFormsModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    FirebaseUIModule.forRoot(firebaseUiAuthConfig)
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
