import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { AngularFirestore } from '@angular/fire/firestore'
import { BehaviorSubject, Observable } from 'rxjs'

@Component({
  selector: 'app-edit-poll',
  templateUrl: './edit-poll.component.html',
  styleUrls: ['./edit-poll.component.css']
})
export class EditPollComponent implements OnInit {

  myForm: FormGroup;
  myDoc: Observable<{}>;

  state: string;

  constructor(private fb: FormBuilder, private afs: AngularFirestore) { }

  ngOnInit() {

    this.myForm = this.fb.group({
      name: ['', Validators.required],
      question: ['', Validators.required],
      answer1: [''],
      answer2: [''],
      answer3: ['']
    })

    this.myDoc = this.afs.doc('polls/test').valueChanges();
  }


  changeHandler(e) {
    console.log(e)
    this.state = e;
  }

}
