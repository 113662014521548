import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-list-polls',
  templateUrl: './list-polls.component.html',
  styleUrls: ['./list-polls.component.css']
})
export class ListPollsComponent implements OnInit {

  ngOnInit() {
  }
  items: Observable<any[]>;
  constructor(afs: AngularFirestore) {
    this.items=afs.collection('polls').valueChanges();
  }
}
